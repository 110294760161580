<template>
  <div>
    <b-table-simple>
      <tr v-for="(option, index) in selected" :key="index">
        <td>
          <strong>{{ option.Value }}</strong>
          <em class="option-description">{{ option.Description }}</em>

        </td>
        <td class="action-icons"><b-icon-x @click="remove(index)" width="30" /></td>
      </tr>
    </b-table-simple>

    <div v-if="selected.length == 0" class="mt-4 mb-4"><strong>Geen gebieden geselecteerd</strong></div>

    <v-select
      v-model="newValue"
      :options="options"
      label="Value"
      />
    
    <b-button class="mt-2" @click="add">Toevoegen</b-button>
  </div>
</template>

<script>
  export default {
    name: 'MultiSelectList',
    components: {
      'v-select': () => import('vue-select')
    },
    model: {
      prop: 'selected'
    },
    data () {
      return {
        newValue: null,
        
      }
    },
    props: {
      selected: Array,
      options: Array,
    },
    methods: {
      add () {
        if (this.newValue == null) return
        let exists = false
        this.selected.forEach(o => {
          if (o.JsonValue == this.newValue.JsonValue) exists = true
        })
        if (!exists)
          this.selected.push(this.newValue)
        this.newValue = null
      },
      remove (index) {
        console.log(this.selected, index)
        this.selected.splice(index, 1)
      }
    },
    watch: {
      selected () {
        this.$emit('input', this.selected)
      }
    },
    computed: {

    },
    mounted () {
    }
  }
</script>

<style lang="scss" scoped>
  em.option-description {
    display:block;
    margin-top: 5px;
  }
</style>