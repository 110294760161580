var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        _vm._l(_vm.selected, function(option, index) {
          return _c("tr", { key: index }, [
            _c("td", [
              _c("strong", [_vm._v(_vm._s(option.Value))]),
              _c("em", { staticClass: "option-description" }, [
                _vm._v(_vm._s(option.Description))
              ])
            ]),
            _c(
              "td",
              { staticClass: "action-icons" },
              [
                _c("b-icon-x", {
                  attrs: { width: "30" },
                  on: {
                    click: function($event) {
                      return _vm.remove(index)
                    }
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm.selected.length == 0
        ? _c("div", { staticClass: "mt-4 mb-4" }, [
            _c("strong", [_vm._v("Geen gebieden geselecteerd")])
          ])
        : _vm._e(),
      _c("v-select", {
        attrs: { options: _vm.options, label: "Value" },
        model: {
          value: _vm.newValue,
          callback: function($$v) {
            _vm.newValue = $$v
          },
          expression: "newValue"
        }
      }),
      _c("b-button", { staticClass: "mt-2", on: { click: _vm.add } }, [
        _vm._v("Toevoegen")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }